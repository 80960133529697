@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background: #040714;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 15rem;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #c0c0c0;
  height: 0.2rem;
}

/******** Firefox ********/
input[type="range"]::-moz-range-track {
  background: #c0c0c0;
  height: 0.2rem;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -12px; /* Centers thumb on the track */
  background-color: #fff;
  height: 2rem;
  width: 1rem;
}

input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 999px; /*Removes default border-radius that FF applies*/
  background-color: #fff;
  height: 0.8rem;
  width: 0.8rem;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

ul[role="navigation"] {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.active-pagination-item {
  background-color: #016cf9;
  border-radius: 4px;
  font-weight: bold;
}

.pagination-page-link {
  /* background-color: red; */
  padding: 4px 8px;
  border-radius: 4px;
}

.pagination-page-link:hover {
  background-color: #016cf9;
}

#scrolling_div::-webkit-scrollbar {
  height: 12px;
  width: 3px;
  background: transparent;
}

#scrolling_div::-webkit-scrollbar-thumb {
  background: #838383;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

#scrolling_div::-webkit-scrollbar-corner {
  background: #000;
}
